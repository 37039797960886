import React, {createContext, useEffect, useState} from "react";
import {DrupalMenuLinkContent} from "next-drupal";
import {getMenuItems} from "@/lib/drupalFetcher";

const MenuLinksContext: React.Context<any | {}> = createContext({});

export const MenuLinksProvider = ({children}) => {

  const [menuHeaderLinks, setMenuHeaderLinks] = useState<DrupalMenuLinkContent[]>()
  const [menuMainLinks, setMenuMainLinks] = useState<DrupalMenuLinkContent[]>()
  const [menuFooterLinks, setMenuFooterLinks] = useState<DrupalMenuLinkContent[]>()

  async function fetchMenuHeader():Promise<void> {
    setMenuHeaderLinks(await getMenuItems('pre-header'))
  }

  async function fetchMenuMain():Promise<void> {
    setMenuMainLinks(await getMenuItems('main'))
  }

  async function fetchMenuFooter():Promise<void> {
    setMenuFooterLinks(await getMenuItems('footer'))
  }

  useEffect(() => {
      void fetchMenuHeader()
      void fetchMenuMain()
      void fetchMenuFooter()
  }, []);

  return (
    <MenuLinksContext.Provider value={{ menuHeaderLinks, menuMainLinks, menuFooterLinks }}>
      {children}
    </MenuLinksContext.Provider>
  );
}

export default MenuLinksContext
