import * as React from "react"
import {Slot, Slottable} from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/lib/utils"
import {ReactElement} from "react";

const buttonVariants = cva(
  "whitespace-nowrap rounded text-md font-regular ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-brand-mer-500 active:bg-brand-mer-900",
        secondary:
          "border border-secondary-foreground bg-white text-secondary-foreground hover:bg-secondary hover:border-secondary hover:text-primary active:bg-white active:border-brand-mer-900 active:text-brand-mer-900",
        tertiary:
          "border border-accent bg-white text-foreground-dark hover:bg-secondary hover:border-secondary active:bg-white active:border-accent",
        ghost: "text-brand-mer-600 hover:bg-accent hover:text-accent-foreground",
        radio: "text-neutral-200 hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline p-0",
        outline: "border border-accent bg-neutral-50",
        input: "border border-input bg-neutral-50"
      },
      display: {
        default: "inline-flex items-center justify-center",
        flex: "flex items-center justify-between w-full"
      },
      size: {
        default: "h-12 px-8 py-3",
        sm: "h-9 px-3 text-xs",
        lg: "h-14 px-8",
        icon: "h-12 w-12",
        iconPlayerLive: "h-12 md:px-8 md:py-3 max-md:w-12",
        preheader: "h-7 px-3 py-2 text-[10px]",
        input: "h-12 px-3 py-4"
      },
    },
    defaultVariants: {
      variant: "default",
      display: "default",
      size: "default",
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean,
  iconLeft?: ReactElement
  iconRight?: ReactElement
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, display,size, iconLeft, iconRight, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    className = (iconLeft || iconRight) && className + ' gap-3'
    return (
      <Comp
        className={cn(buttonVariants({ variant, display, size, className }))}
        ref={ref}
        {...props}
      >
        { iconLeft }
        <Slottable>{props.children}</Slottable>
        { iconRight }
      </Comp>
    )
  }
)
Button.displayName = "Button"

export { Button, buttonVariants }
