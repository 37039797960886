"use client"

import React, {ReactElement} from "react";
import {cn} from "@/lib/utils";

export interface PlayerStateProps extends React.HTMLAttributes<HTMLElement> {
    isOnAir?: boolean,
}

const PlayerState = ({className = undefined, isOnAir = false, ...props}:PlayerStateProps): ReactElement => (
    <div className={
        cn(
            "p-2 inline-flex w-fit gap-2 items-center text-[10px] uppercase bg-playerState text-white leading-none",
            className
        )}
         {...props}
    >
        {isOnAir && (<div className='h-3 w-3 rounded-full bg-playerState-onAir'></div>)}
        {props.children}
    </div>
)

export { PlayerState }
