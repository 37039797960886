import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import {DrupalNode} from "next-drupal";
import { DateTime } from "luxon";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function formatDate(input: string): string {
  const date: Date = new Date(input)
  return date.toLocaleDateString("fr-FR", {
    month: "long",
    day: "numeric",
    year: "numeric"
  })
}

export function formatDatetime(input: string): string {
  const date: Date = new Date(input)
  return date.toLocaleDateString("fr-FR", {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  })
}

export function formatTime(input: string): string {
  const date: Date = new Date(input)
  return date.toLocaleTimeString("fr-FR", {
    hour: "numeric",
    minute: "numeric",
  })
}

export function weekDayByNumber(dayNumber: number): string {
  return ["Dimanche","Lundi","Mardi","Mercredi","Jeudi","Vendredi","Samedi"][dayNumber] || '';

}

export function absoluteUrl(input: string) {
  return `${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL}${input}`
}

export function formatPlayerTime(time) {
  if (time && !isNaN(time)) {
    const minutes = Math.floor(time / 60);
    const formatMinutes =
        minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(time % 60);
    const formatSeconds =
        seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${formatMinutes}:${formatSeconds}`;
  }
  return '00:00';
}

export function dateDiff(date1, date2) {
  let result = {
    seconds: 0,
    minutes: 0,
    hours: 0,
    days: 0,
  };
  result.seconds = Math.round((date1 - date2) / 1000);
  result.minutes = Math.round((date1 - date2) / 60000);
  result.hours = Math.round((date1 - date2) / 3600000);
  result.days = Math.round((date1 - date2) / 86400000);

  return result;
}

export function extractYouTubeID(url: string): string {
  let regex = /(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtube\.com\/v\/|youtu\.be\/)([^\s&]+)/;
  let matches = url.match(regex);
  return matches ? matches[4] : '';
}

export function youtubeEmebedURL(url: string): string {
  const idVideo = extractYouTubeID(url);
  return `https://www.youtube.com/embed/${idVideo}`;
}

export function radioLiveEmissionIsIncoming(item: DrupalNode, date: DateTime): boolean {
  return (parseInt(item.heure_fin) > date.hour) || (parseInt(item.heure_fin) === date.hour && parseInt(item.minute_fin) > date.minute)
}

export function reloadRevive() {
  if (window.hasOwnProperty('reviveAsync') && process.env.NEXT_PUBLIC_REVIVE_ID) {
    window['reviveAsync'][process.env.NEXT_PUBLIC_REVIVE_ID].apply(window['reviveAsync'][process.env.NEXT_PUBLIC_REVIVE_ID].detect())
  }
}
