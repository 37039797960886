"use client"

import {useAudioPlayer} from "@/components/contexts/AudioPlayerContext";
import React from "react";
import {AudioPlayerContextMetadatasContent} from "@/types/types";

export interface AudioPlayerButtonProps extends React.HTMLAttributes<HTMLElement> {
    media?: {
        src?: string | undefined,
        metadatas: AudioPlayerContextMetadatasContent
    },
}

const AudioPlayerButton = ({media = {metadatas: {illustration: undefined}}, ...props}: AudioPlayerButtonProps) => {
    const {
        src,
        metadatas
    } = media

    const {
        setAudioSrc,
        setIsPlaying,
        setMetadatas,
    } = useAudioPlayer()

    const handlePlay = () => {
        setAudioSrc(src)
        setIsPlaying(true)
        setMetadatas(metadatas)
    }

    return (
        <button onClick={handlePlay} {...props}></button>
    )
}

export default AudioPlayerButton
