import React, {useEffect, useState} from 'react';
import {IconVolume, IconVolume2, IconVolumeOff} from "@tabler/icons-react";
import {Slider} from "@/components/ui/slider";
import {useAudioPlayer} from "@/components/contexts/AudioPlayerContext";

const AudioControlVolume = () => {

  const {
    currentVolume,
    setCurrentVolume,
    toggleMute,
  } = useAudioPlayer()

  const [sliderValue, setSliderValue] = useState(1);
  const [isInteracting, setIsInteracting] = useState<boolean>(false);

  useEffect(() => {
    if (!isInteracting) {
      setSliderValue(currentVolume);
    }
  }, [currentVolume, isInteracting]);

  const handleSliderChange = (value) => {
    setCurrentVolume(value[0])
  };

  const handleDragStart = () => {
    setIsInteracting(true);
  };

  const handleDragEnd = () => {
    setIsInteracting(false);
  };

  return (
    <div className="group flex gap-2 items-center">
      <button title='volume' onClick={toggleMute} className="p-[10px]">
        {currentVolume == 0 && (<IconVolumeOff/>)}
        {currentVolume > 0 && currentVolume <= 0.5 && (<IconVolume2/>)}
        {currentVolume > 0.5 && currentVolume <= 1 && (<IconVolume/>)}
      </button>
      <div className="w-0 transition-[width] group-hover:w-[95px] opacity-0 group-hover:opacity-100">
        <Slider
          className="w-100"
          min={0}
          max={1}
          step={0.1}
          value={isInteracting ? undefined : [sliderValue]}
          onValueChange={handleSliderChange}
          onPointerDown={handleDragStart}
          onPointerUp={handleDragEnd}
        />
      </div>
    </div>
  );
};

export default AudioControlVolume;