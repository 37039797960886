export function PlayerBackward({size = '24', color = 'currentColor', ...props}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 22 25" fill="none" {...props}>
            <path d="M8.80347 3.60037L11.0305 0.822266" stroke={color} strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path
                d="M2.02161 17.6487C3.63 21.0005 7.06051 23.3175 11.0308 23.3175C16.5534 23.3175 21.03 18.841 21.03 13.3184C21.03 7.79588 16.5534 3.31934 11.0308 3.31934C10.266 3.31934 9.52379 3.42062 8.80395 3.58933"
                stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.05456 7.3457C1.80609 9.01034 1.03003 11.0686 1.03003 13.3181" stroke={color} strokeWidth="1.5"
                  strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}