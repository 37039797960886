import {Progress} from "@/components/ui/progress";
import {useAudioPlayer} from "@/components/contexts/AudioPlayerContext";
import * as React from "react";

const AudioControlMobileProgress = ({className , ...props}:React.HTMLAttributes<HTMLElement>) => {
    const {
        timeProgress,
        duration,
    } = useAudioPlayer()


    return (
        <div className={className} {...props}>
            <Progress value={(timeProgress / duration) * 100}/>
        </div>
    )
}

export default AudioControlMobileProgress;