import {useEffect, useRef, useState} from "react";
import {cn, formatPlayerTime} from "@/lib/utils";
import {Slider} from "@/components/ui/slider";
import {useAudioPlayer} from "@/components/contexts/AudioPlayerContext";
import * as React from "react";

const AudioControlProgress = ({className, ...props}: React.HTMLAttributes<HTMLElement>) => {
  const {
    timeProgress,
    duration,
    setCurrentTime,
  } = useAudioPlayer()

  const [sliderValue, setSliderValue] = useState(0);
  const [isInteracting, setIsInteracting] = useState(false);

  useEffect(() => {
    if (!isInteracting && duration > 0) {
      setSliderValue((timeProgress / duration) * 100);
    }
  }, [timeProgress, duration, isInteracting]);

  const handleSliderChange = (value) => {
    setSliderValue(value[0]);
  };

  const handleSliderCommit = () => {
    const newTime = (sliderValue / 100) * duration;
    setCurrentTime(newTime)
  };

  const handleDragStart = () => {
    setIsInteracting(true);
  };

  const handleDragEnd = () => {
    setIsInteracting(false);
  };

  return (
    <div className={
      cn(
        'flex gap-3 w-full items-center',
        className
      )} {...props}>
      <span className='text-[10px]'>{formatPlayerTime(timeProgress)}</span>
      <Slider
        max={100}
        step={1}
        value={isInteracting ? undefined : [sliderValue]}
        onValueChange={handleSliderChange}
        onValueCommit={handleSliderCommit}
        onPointerDown={handleDragStart}
        onPointerUp={handleDragEnd}
      />
      <span className='text-[10px]'>{formatPlayerTime(duration)}</span>
    </div>
  )
}

export default AudioControlProgress;