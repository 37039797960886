"use client"
import React, {useEffect} from "react";
import {useAudioPlayer} from "@/components/contexts/AudioPlayerContext";

const streamUrl = 'https://webradio.lagoon.nc/rrb';

const AudioPlayer = () => {
  const {
    playerRef,
    playAnimationRef,
    audioSrc,
    setDuration,
    setIsPlaying,
    isPlaying,
    currentSpeed,
    currentVolume,
    repeat,
  } = useAudioPlayer()

  const setLiveSrc = () => { // Pour la radio live, on reset la source pour reprendre le live en temps reel
    playerRef.current.src = '';
    playerRef.current.src = streamUrl;
  }

  const onLoadedMetadata = () => {
    const seconds = playerRef.current.duration;
    setDuration(seconds);
  };

  const onEnded = () => {
    setIsPlaying(false);
  }

  const setPlay = async () => {
    if (!audioSrc) {
      setLiveSrc()
    }
    await playerRef.current.play();
  }

  const setPause = async () => {
    await playerRef.current.pause();
    if (!audioSrc) {
      playerRef.current.src = '';
    }
  }

  useEffect(() => {
    playerRef.current.playbackRate = currentSpeed;
  }, [currentSpeed]);

  useEffect(() => {
    playerRef.current.volume = currentVolume;
  }, [currentVolume]);

  useEffect(() => {
    playerRef.current.load()
    if (!audioSrc) {
      setLiveSrc()
    }
  }, [audioSrc]);

  useEffect(() => {
    const init = async () => {
      if (isPlaying) {
        await setPlay()
        if (audioSrc) {
          playAnimationRef.current = requestAnimationFrame(repeat);
        }
      } else {
        await setPause()
        if (audioSrc) {
          cancelAnimationFrame(playAnimationRef.current);
        }
      }
    }
    init()
      .then()
      .catch((err) => console.error(err))
  }, [isPlaying, playerRef, repeat]);

  const onCanPlay = async () => {
    if (isPlaying) {
      await playerRef.current.play();
    }
  }

  return (
    <audio className='hidden' src={audioSrc} controls ref={playerRef} onLoadedMetadata={onLoadedMetadata}
           onCanPlay={onCanPlay} onEnded={onEnded}/>
  )
}

export default AudioPlayer;
