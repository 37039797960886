"use client"

import {PlayerState} from "@/components/ui/player-state";
import {
  IconPlayerPlayFilled,
  IconPlayerPauseFilled,
  IconChevronDown
} from "@tabler/icons-react";
import {PlayerBackward, PlayerForward} from "@/components/svg";
import {useAudioPlayer} from "@/components/contexts/AudioPlayerContext";
import audioDefaultThumbnail from "@/public/audio-default-thumbnail.jpg"
import {AudioControlSpeed, AudioControlProgress} from "@/components/audioPlayer";
import Image from "next/image";
import {useEffect, useState} from "react";
import {AudioControlMobileProgress} from "@/components/audioPlayer";
import AudioControlVolume from "@/components/audioPlayer/AudioControlVolume";
import Link from "next/link";

const AudioControls = () => {
  const [isMobileControls, setIsMobileControls] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 639.98px)')
    mediaQuery.addEventListener('change', checkIfMobileControls)
    checkIfMobileControls(mediaQuery)
  }, []);

  const checkIfMobileControls = (e) => {
    setIsMobileControls(e.matches);
  }

  const {
    metadatas,
    audioSrc,
    isPlaying,
    togglePlay,
    handleBackward,
    handleForward,
  } = useAudioPlayer()

  const {
    illustration = audioDefaultThumbnail.src,
    title = '',
    episode = '',
    presenter = '',
    music = '',
    link = '',
  } = metadatas;

  // S'il n'y a pas de présentateurs défini
  if (metadatas.presenter === 'undefined') {
    metadatas.presenter = '';
  }

  return (
    <div className={(isOpen ? 'z-50' : 'z-10') + ' fixed w-full bottom-0 p-4 '}>
      {isMobileControls ? (
        <>
          {isOpen ? (
            <>
              <div className='fixed bg-black/25 inset-0 animate-in fade-in' onClick={() => setIsOpen(false)}></div>
              <div className='text-white pb-[72px] rounded-lg shadow-player-mobile relative min-h-[80vh] z-50' style={{
                background: `linear-gradient(0, var(--neutral-1000) 16%, rgba(58, 63, 75, 0.00) 55%), url(${illustration}) top/auto 90% no-repeat, var(--neutral-1000)`,
              }}>
                <button onClick={() => setIsOpen(!isOpen)} className="absolute p-3 top-2 left-2 text-neutral-400">
                  <IconChevronDown size='24'/></button>

                <div className="px-3 absolute bottom-10 left-0 right-0">
                  <div className="mb-6">
                    {!audioSrc ? (
                      <>
                        <div>
                          <PlayerState isOnAir className='bg-[--player-state-alt]'>En
                            direct</PlayerState>
                        </div>
                        {music && music.title !== "RRB" && (
                          <span className="text-sm truncate">
                          {music && music.title}
                            {" - "}
                            {music && music.artists}
                        </span>
                        )}
                        <div className='flex gap-2 items-center'>
                          {link ? (
                            <Link href={link} className='text-sm'>
                              {title}
                            </Link>
                          ) : (
                            <span className='text-sm'>{title}</span>
                          )}
                          <span className='text-xs text-neutral-300'>{presenter}</span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          <span className='text-sm'>{episode}</span>
                        </div>
                        <div className='flex gap-2 items-center h-7'>
                          <span className='text-sm'>{title}</span>
                          <span className='text-xs text-neutral-300'>{presenter}</span>
                        </div>
                      </>
                    )}
                  </div>

                  <div className='flex flex-col gap-1 items-center'>
                    {audioSrc && (
                      <AudioControlProgress className="text-neutral-300"/>
                    )}

                    <div className='flex items-center gap-6'>
                      {audioSrc &&
                          <button onClick={handleBackward} title='-15'><PlayerBackward/></button>}
                      <button onClick={togglePlay}>{isPlaying ? (
                        <IconPlayerPauseFilled size='32'/>) : (
                        <IconPlayerPlayFilled size='32'/>)}</button>
                      {audioSrc &&
                          <button onClick={handleForward} title='+15'><PlayerForward/></button>}
                    </div>
                  </div>

                  {audioSrc && (
                    <div className='text-center mt-8'>
                      <AudioControlSpeed/>
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <div className='rounded-lg overflow-hidden shadow-player-mobile'>
              <div className='bg-neutral-1000 text-white p-2 flex justify-between items-center'>
                <div className='flex gap-3 items-center min-w-0'>
                  <Image onClick={() => setIsOpen(!isOpen)} src={illustration} alt="" width="112"
                         height="112"
                         className="h-[56px] w-[56px] object-cover rounded bg-primary cursor-pointer"/>
                  <div className="inline-flex flex-col min-w-0">
                    {!audioSrc ? (
                      <>
                        <div className='inline-flex gap-2 items-center'>
                          {music && music.title !== "RRB" && (
                            <span className="text-sm truncate">
                              {music && music.title}
                              {" - "}
                              {music && music.artists}
                            </span>
                          )}
                          <PlayerState isOnAir className='bg-[--player-state-alt]'>En
                            direct</PlayerState>
                        </div>
                        <div className='inline-flex gap-2 items-center'>
                          {link ? (
                            <Link href={link} className='text-sm'>
                              {title}
                            </Link>
                          ) : (
                            <span className='text-sm'>{title}</span>
                          )}
                          <span className='text-xs text-neutral-300'>{presenter}</span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          <span className='text-sm'>{episode}</span>
                        </div>
                        <div className='flex gap-2 items-center h-7'>
                          <span className='text-sm'>{title}</span>
                          <span className='text-xs text-neutral-300'>{presenter}</span>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className='flex flex-col gap-1 items-center'>
                  <div className='flex items-center gap-6'>
                    <button className='p-[14px]' onClick={togglePlay}>{isPlaying ? (
                      <IconPlayerPauseFilled size='20'/>) : (
                      <IconPlayerPlayFilled size='20'/>)}</button>
                  </div>
                </div>
              </div>

              {audioSrc && (
                <AudioControlMobileProgress/>
              )}
            </div>
          )}
        </>
      ) : (
        <div className='bg-neutral-1000 text-white shadow-player p-2 rounded-lg grid grid-cols-3 items-center'>
          <div className='flex gap-3 items-center'>
            <Image src={illustration} alt="" width="120" height="120"
                   className="h-[60px] w-[60px] object-cover rounded bg-primary"/>
          <div>
              {!audioSrc ? (
                <>
                  <div className='flex gap-2 items-center'>
                    {music && music.title !== "RRB" && (
                      <span className="text-sm truncate">
                        {music && music.title}
                        {" - "}
                        {music && music.artists}
                      </span>
                    )}
                    <PlayerState isOnAir className='bg-[--player-state-alt]'>En direct</PlayerState>
                  </div>
                  <div className='flex gap-2 items-center'>
                    {link ? (
                      <Link href={link} className='text-sm'>
                        {title}
                      </Link>
                    ) : (
                      <span className='text-sm'>{title}</span>
                    )}
                    <span className='text-xs text-neutral-300'>{presenter}</span>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <span className='text-sm'>{episode}</span>
                  </div>
                  <div className='flex gap-2 items-center h-7'>
                    <span className='text-sm'>{title}</span>
                    <span className='text-xs text-neutral-300'>{presenter}</span>
                  </div>
                </>
              )}

            </div>
          </div>

          <div className='flex flex-col gap-1 items-center'>
            <div className='flex items-center gap-6'>
              {audioSrc && <button onClick={handleBackward} title='-15'><PlayerBackward/></button>}
              <button onClick={togglePlay}>{isPlaying ? (<IconPlayerPauseFilled size='32'/>) : (
                <IconPlayerPlayFilled size='32'/>)}</button>
              {audioSrc && <button onClick={handleForward} title='+15'><PlayerForward/></button>}
            </div>

            {audioSrc && (
              <AudioControlProgress className="text-neutral-300"/>
            )}
          </div>

          <div className='pe-10 flex gap-6 items-center justify-end'>
            {audioSrc && (<AudioControlSpeed/>)}
            <AudioControlVolume />
          </div>
        </div>
      )}
    </div>
  )
}

export default AudioControls;
