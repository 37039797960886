'use client'

import Script from 'next/script'

const CookieBanner = () => {

  const customText = {
    "en": {
      "alertBigPrivacy":"Cookies are used to store your connection information and to compile statistics in order to optimize the services of the site and to adapt our content and our advertising campaigns to personalize and improve your experience. For more information, see the privacy policy.",
      "alertBig":"Cookies are used to store your connection information and to compile statistics in order to optimize the services of the site and to adapt our content and our advertising campaigns to personalize and improve your experience. For more information, see the privacy policy.",
      "acceptAll":"Accept",
      "allowAll":"Accept",
      "denyAll":"Refuse",
      "middleBarHead":"Use of cookies",
      "close":"×",
      "title":"Cookie management"
    },
    "fr":{
      "alertBigPrivacy":"Des cookies sont utilisés pour conserver vos informations de connexion et réaliser des statistiques en vue d’optimiser les services du site et d’adapter nos contenus et nos campagnes publicitaires pour personnaliser et améliorer votre expérience.Pour en savoir plus, consultez la politique de confidentialité.",
      "alertBig":"Des cookies sont utilisés pour conserver vos informations de connexion et réaliser des statistiques en vue d’optimiser les services du site et d’adapter nos contenus et nos campagnes publicitaires pour personnaliser et améliorer votre expérience.Pour en savoir plus, consultez la politique de confidentialité.",
      "acceptAll":"Accepter",
      "allowAll":"Accepter",
      "denyAll":"Refuser",
      "middleBarHead":"Utilisation des cookies",
      "close":"×",
      "title":"Gestion des Cookies"
    }
  }

  return (
    <Script
      id="gdpr"
      src="https://cdn.jsdelivr.net/npm/tarteaucitronjs@1.19.0/tarteaucitron.min.js"
      onReady={() => {
        // @ts-ignore
        if( typeof tarteaucitron !== 'undefined' ) {

          // @ts-ignore
          const lang = tarteaucitron.getLanguage();

          // Customize the language BEFORE init
          // @ts-ignore
          tarteaucitronCustomText = '';
          if(customText.hasOwnProperty(lang)){
            // @ts-ignore
            tarteaucitronCustomText = customText[lang];
          }

          // @ts-ignore
          tarteaucitron.init({
            "privacyUrl": "/politique-des-cookies", /* Privacy policy url */
            "bodyPosition": "bottom", /* or top to bring it as first element for accessibility */

            "hashtag": "#cookie", /* Open the panel with this hashtag */
            "cookieName": "tarteaucitron", /* Cookie name */

            "orientation": "middle", /* Banner position (top - bottom - middle - popup) */

            "groupServices": false, /* Group services by category */
            "showDetailsOnClick": true, /* Click to expand the description */
            "serviceDefaultState": "wait", /* Default state (true - wait - false) */

            "showAlertSmall": false, /* Show the small banner on bottom right */
            "cookieslist": false, /* Show the cookie list */

            "showIcon": true, /* Show cookie icon to manage cookies */
            // "iconSrc": "", /* Optional: URL or base64 encoded image */
            "iconPosition": "BottomRight", /* Position of the icon between BottomRight, BottomLeft, TopRight and TopLeft */

            "adblocker": false, /* Show a Warning if an adblocker is detected */

            "DenyAllCta" : true, /* Show the deny all button */
            "AcceptAllCta" : true, /* Show the accept all button when highPrivacy on */
            "highPrivacy": true, /* HIGHLY RECOMMANDED Disable auto consent */
            "alwaysNeedConsent": false, /* Ask the consent for "Privacy by design" services */

            "handleBrowserDNTRequest": false, /* If Do Not Track == 1, disallow all */

            "removeCredit": true, /* Remove credit link */
            "moreInfoLink": true, /* Show more info link */
            "useExternalCss": false, /* If false, the tarteaucitron.css file will be loaded */
            "useExternalJs": false, /* If false, the tarteaucitron.services.js file will be loaded */

            // "cookieDomain": ".my-multisite-domaine.fr", /* Shared cookie for subdomain website */

            "readmoreLink": "", /* Change the default readmore link pointing to tarteaucitron.io */

            "mandatory": true, /* Show a message about mandatory cookies */
            "mandatoryCta": true, /* Show the disabled accept button when mandatory on */

            // "customCloserId": "", /* Optional a11y: Custom element ID used to open the panel */

            "googleConsentMode": true, /* Enable Google Consent Mode v2 for Google ads and GA4 */

            "partnersList": false, /* Details the number of partners on the popup and middle banner */
          });

          // Force Eulerian cookie -> neeed to check about gdpr
          // @ts-ignore
          // tarteaucitron.user.eulerianHost = 'erbz.rrb.nc';
          // // @ts-ignore
          // (tarteaucitron.job = tarteaucitron.job || []).push('eulerian');
          // @ts-ignore
          tarteaucitron.user.googletagmanagerId = 'GTM-T4C4ZNFK';
          // @ts-ignore
          (tarteaucitron.job = tarteaucitron.job || []).push('googletagmanager');
        }
      }}
    />
  );
};

export default CookieBanner;
