import React, {createContext, useCallback, useContext, useEffect, useRef, useState} from "react";
import {AudioPlayerContextContent, AudioPlayerContextMetadatasContent, AudioPlayerMusic} from "@/types/types";
import {DrupalNode} from "next-drupal";
import {absoluteUrl, radioLiveEmissionIsIncoming} from "@/lib/utils";
import { DateTime } from "luxon";

const AudioPlayerContext: React.Context<any | {}> = createContext({});

export const useAudioPlayer = (): AudioPlayerContextContent => useContext(AudioPlayerContext) as AudioPlayerContextContent;

export const AudioPlayerContextProvider = ({children}) => {
  const playerRef = useRef<any>()
  const playAnimationRef = useRef<any>()
  const [audioSrc, setAudioSrc] = useState<string | undefined>(undefined)
  const [metadatas, setMetadatas] = useState<AudioPlayerContextMetadatasContent>({})
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const [timeProgress, setTimeProgress] = useState<number>(0)
  const [duration, setDuration] = useState<number>(0)
  const [currentSpeed, setCurrentSpeed] = useState<number>(1)
  const [currentVolume, setCurrentVolume] = useState<number>(1)
  const [tmpVolume, setTmpVolume] = useState<number>(1)
  const [muted, setMuted] = useState<boolean>(false)
  const [emissionsLive, setEmissionsLive] = useState<DrupalNode[]>([])
  const [emissionLive, setEmissionLive] = useState<DrupalNode>()
  const [musicLive, setMusicLive] = useState<AudioPlayerMusic>()
  const [currentDate, setCurrentDate] = useState(DateTime.local().setZone("Pacific/Noumea"))

  const emissionsLiveRef = useRef<DrupalNode[]>(emissionsLive)
  emissionsLiveRef.current = emissionsLive

  const repeat = useCallback(() => {
    const currentTime = playerRef.current?.currentTime;
    setTimeProgress(currentTime);
    playAnimationRef.current = requestAnimationFrame(repeat);
  }, []);

  const togglePlay = () => {
    setIsPlaying((prevState) => !prevState);
  }

  const handleForward = () => {
    if (playerRef.current) {
      playerRef.current.currentTime += 15;
    }
  }

  const handleBackward = () => {
    if (playerRef.current) {
      playerRef.current.currentTime -= 15;
    }
  }

  const setCurrentTime = (value) => {
    if (playerRef.current) {
      setTimeProgress(value);
      playerRef.current.currentTime = value;
    }
  }

  const toggleMute = () => {
    setMuted((prevState) => !prevState);
  }

  const fetchMusicLive = () => {
    fetch('/api/on-air')
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'success' && typeof res.data === 'object') {
          setMusicLive(res.data)
        } else {
          setMusicLive(undefined)
        }
      }).catch((err) => {
        console.error(err)
    })
  }

  const fetchRadioLive = () => {
    fetch(process.env.NEXT_PUBLIC_DRUPAL_BASE_URL + '/radio-live-view/now?' + new URLSearchParams({
      field_heure_end: 'now'
    }))
      .then((res) => res.json())
      .then((data) => {
        setEmissionsLive(data)
      }).catch(e => console.error(e))
  }

  const clearData = () => {
    const newDate = DateTime.local().setZone("Pacific/Noumea");
    let clearedData: DrupalNode[] = [];
    emissionsLiveRef.current.map((item, index) => {
      if (radioLiveEmissionIsIncoming(item, newDate)) {
        clearedData.push(item)
      }
    })
    setEmissionLive(clearedData[0])
  }

  useEffect(() => {
    fetchRadioLive()
    fetchMusicLive()
    const dataInterval = setInterval(fetchRadioLive, 3600000);
    const displayInterval = setInterval(clearData, 60000);
    const musicInterval = setInterval(fetchMusicLive, 30000);
    return () => {
      clearInterval(dataInterval)
      clearInterval(displayInterval)
      clearInterval(musicInterval)
    }
  }, []);

  useEffect(() => {
    clearData()
  }, [emissionsLive]);

  useEffect(() => {
    if(!audioSrc){
      setMetadatas({
        illustration: emissionLive ? absoluteUrl(emissionLive.field_media_image) : undefined,
        title: emissionLive?.title,
        presenter: emissionLive?.field_animateurs,
        music: musicLive,
        link: emissionLive ? emissionLive.link : undefined
      })
    }
  }, [emissionLive, musicLive, audioSrc]);

  useEffect(() => {
    if(muted){
      setTmpVolume(currentVolume)
      setCurrentVolume(0)
    } else {
      setCurrentVolume(tmpVolume)
    }
  }, [muted]);

  return (
    <AudioPlayerContext.Provider value={{
      playerRef,
      playAnimationRef,
      audioSrc,
      setAudioSrc,
      metadatas,
      setMetadatas,
      isPlaying,
      setIsPlaying,
      timeProgress,
      setTimeProgress,
      duration,
      setDuration,
      currentSpeed,
      setCurrentSpeed,
      currentVolume,
      setCurrentVolume,
      repeat,
      togglePlay,
      toggleMute,
      handleForward,
      handleBackward,
      setCurrentTime,
    }}
    >
      {children}
    </AudioPlayerContext.Provider>
  )
}
