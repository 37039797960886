export function PlayerForward({size = '24', color = 'currentColor', ...props}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 22 25" fill="none" {...props}>
            <path d="M13.1321 3.60037L10.9051 0.822266" stroke={color} strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path
                d="M19.9139 17.6487C18.3055 21.0005 14.875 23.3175 10.9047 23.3175C5.38215 23.3175 0.905516 18.841 0.905516 13.3184C0.905516 7.79588 5.38215 3.31934 10.9047 3.31934C11.6695 3.31934 12.4118 3.42062 13.1316 3.58933"
                stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18.881 7.3457C20.1295 9.01034 20.9055 11.0686 20.9055 13.3181" stroke={color} strokeWidth="1.5"
                  strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}