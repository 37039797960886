import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel, DropdownMenuSeparator,
    DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import {Button} from "@/components/ui/button";
import {useAudioPlayer} from "@/components/contexts/AudioPlayerContext";

const AudioControlSpeed = () => {
    const {
        currentSpeed,
        setCurrentSpeed,
    } = useAudioPlayer()

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant='secondary' size='sm' className='text-xs'>Vitesse
                    x {currentSpeed}</Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className='min-w-[256px] border-neutral-800 bg-neutral-1000 text-neutral-300 shadow-md'>
                <DropdownMenuLabel className='uppercase mb-7 text-center'>Vitesse de
                    lecture</DropdownMenuLabel>

                <DropdownMenuItem onClick={() => setCurrentSpeed(0.5)}>
                    X 0,5
                    <DropdownMenuCheckboxItem checked={currentSpeed === 0.5}/>
                </DropdownMenuItem>
                <DropdownMenuSeparator/>
                <DropdownMenuItem onClick={() => setCurrentSpeed(1)}>
                    X 1 (PAR DÉFAUT)
                    <DropdownMenuCheckboxItem checked={currentSpeed === 1}/>
                </DropdownMenuItem>
                <DropdownMenuSeparator/>
                <DropdownMenuItem onClick={() => setCurrentSpeed(1.2)}>
                    X 1,2
                    <DropdownMenuCheckboxItem checked={currentSpeed === 1.2}/>
                </DropdownMenuItem>
                <DropdownMenuSeparator/>
                <DropdownMenuItem onClick={() => setCurrentSpeed(1.5)}>
                    X 1,5
                    <DropdownMenuCheckboxItem checked={currentSpeed === 1.5}/>
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}

export default AudioControlSpeed;
