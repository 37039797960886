import { AppProps } from "next/app"
import { Rubik as FontSans } from 'next/font/google'
import "styles/globals.css"
import "styles/cookie-banner.css"
import {AudioPlayer} from "@/components/audioPlayer";
import {AudioPlayerContextProvider} from "@/components/contexts/AudioPlayerContext";
import Script from "next/script";
import {MenuLinksProvider} from "@/components/contexts/MenuLinksContext";
import {useRouter} from "next/router";
import {useEffect} from "react";
import NextTopLoader from 'nextjs-toploader';
import {CookieBanner, Eulerian} from "@/components/GDPR";

const fontSans = FontSans({
  weight: ['300', '400', '500', '600', '700', '800', '900'],
  style: ['normal', 'italic'],
  subsets: ['latin'],
  display: 'swap',
})

const trackPage = (url) => {
  // @ts-ignore
  if (typeof EA_push !== 'undefined') {
    var EA_datalayer = [
      // Information Page
      "path", url
    ];
    // @ts-ignore
    EA_push(EA_datalayer);
  }
}

export default function App({ Component, pageProps }: AppProps) {

  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeComplete', (url) => {
      trackPage(url)
    });
    trackPage(window.location.pathname)
  }, []);


  return (
    <>
      <style jsx global>{`
          html {
              --font-sans: ${fontSans.style.fontFamily};
          }
      `}</style>
      <NextTopLoader color="#52C8FF" shadow={false}/>
      <AudioPlayerContextProvider>
        <AudioPlayer/>
        <MenuLinksProvider>
          <Component {...pageProps} />
        </MenuLinksProvider>
      </AudioPlayerContextProvider>
      <Eulerian />
      {/*<CookieBanner/>*/}
      <Script src="https://diffusion.skazy.nc/www/delivery/asyncjs.php" strategy="lazyOnload"/>
    </>
  )
}
